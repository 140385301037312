import * as React from "react";


export default class Contact extends React.Component {
    render() {
        return <>

        <section className="section section-lg bg-default" id="contact">
            <div className="container">
                <h2 className="text-uppercase font-weight-bold">Võta ühendust</h2>
                <form className="rd-form rd-mailform form-lg" data-form-output="form-output-global"
                      data-form-type="contact" method="post" action="bat/rd-mailform.php">
                    <div className="row row-30">
                        <div className="col-lg-4">
                            <div className="form-wrap form-wrap-icon">
                                <input className="form-input" id="contact-name" type="text" name="name"
                                       data-constraints="@Required"/>
                                    <label className="form-label" htmlFor="contact-name">Nimi</label>
                                    <div className="icon form-icon mdi mdi-account-outline text-primary"/>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-wrap form-wrap-icon">
                                <input className="form-input" id="contact-phone" type="text" name="phone"
                                       data-constraints="@PhoneNumber"/>
                                    <label className="form-label" htmlFor="contact-phone">Telefon</label>
                                    <div className="icon form-icon mdi mdi-phone text-primary"/>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-wrap form-wrap-icon">
                                <input className="form-input" id="contact-email" type="email" name="email"
                                       data-constraints="@Email @Required"/>
                                    <label className="form-label" htmlFor="contact-email">E-mail</label>
                                    <div className="icon form-icon mdi mdi-email-outline text-primary"/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-wrap form-wrap-icon">
                                <label className="form-label" htmlFor="contact-message">Sõnum</label>
                                <textarea className="form-input" id="contact-message" name="message"
                                          data-constraints="@Required"/>
                                <div className="icon form-icon mdi mdi-message-outline text-primary"/>
                            </div>
                        </div>
                    </div>
                    <div className="form-wrap form-wrap-button">
                        <button className="button button-lg button-primary" type="submit">Saada</button>
                    </div>
                </form>
            </div>
        </section>
            </>;
    }
}