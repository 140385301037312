import React from 'react';
import Navmenu from './components/navmenu';
import Preloader from './assets/images/preloader-default.png';
import Home from "./components/home";
import Services from "./components/services";
import Events from "./components/events";
import About from "./components/about";
import Merch from "./components/merch";
import Footer from "./components/footer";
import Contact from "./components/contact";
import Feedback from "./components/feedback";

class App extends React.Component {
    render() {
        return <div>
            <div className="preloader">
                <div className="preloader-inner">
                    <div className="preloader-top">
                        <div className="preloader-top-sun"><img src={Preloader} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page">
                <Navmenu/>
                <Home/>
                <Services/>
                <Events/>
                <About/>
                <Feedback/>
{/*                <Merch/>*/}
                <Contact/>
                <Footer/>
            </div>
        </div>
    }
}

export default App;
