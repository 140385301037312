import * as React from "react";
import Img1 from "../assets/images/img1.jpg";
import Img2 from "../assets/images/img2.jpg";
import Img3 from "../assets/images/img3.jpg";

export default class Services extends React.Component {
    render() {

        return <section className="section section-lg bg-tertiary text-center context-dark" id="services">
            <div className="container">
                <h2 className="text-uppercase wow fadeIn pt-6 with-divider-white">Telli esinema</h2>
                <div className="row row-30 row-xl-40 mt-lg-60 mt-xl-80">
                    <div className="col-sm-6 col-lg-4 wow fadeIn">
                        <article className="tour-classic">
                            <div className="tour-classic-media"><a className="tour-classic-figure"
                                                                   href="#"><img
                                className="tour-classic-image" src={Img2} alt="" width="365"
                                height="248"/></a>
                                <div className="tour-classic-pricing">
                                    <p className="tour-classic-price tour-classic-price-new">Fonogram</p>
                                </div>
                            </div>
                            <div className="tour-classic-body">
                                <h4 className="tour-classic-title"><a href="#">Artjom Savitski</a></h4>
                                <p className="tour-classic-caption">Kõige lihtsam ja eelarvesõbralikum variant, kui on soov pidutseda, tantsida või näppu visata.
                                    Sobib samuti vahepalaks üritustele, konverentsidele jms.</p>
                                <div className="tour-classic-footer">
                                    <div className="tour-classic-footer-left">
                                        <p className="tour-classic-rating">Kestus:</p>
                                        <p className="tour-classic-reviews"><a href="#">kuni 40 min.</a></p>
                                    </div>
                                    <div className="tour-classic-footer-left">
                                        <p className="tour-classic-rating">Sobivus:</p>
                                        <p className="tour-classic-reviews"><a href="#">EST, ENG, RUS</a></p>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="col-sm-6 col-lg-4 wow fadeIn" data-wow-delay=".025s">
                        <article className="tour-classic">
                            <div className="tour-classic-media"><a className="tour-classic-figure"
                                                                   href="#"><img
                                className="tour-classic-image" src={Img3} alt="" width="365"
                                height="248"/></a>
                                <div className="tour-classic-pricing">
                                    <p className="tour-classic-price tour-classic-price-new">Duo</p>
                                </div>
                            </div>
                            <div className="tour-classic-body">
                                <h4 className="tour-classic-title"><a href="#">Akustiline duo</a></h4>
                                <p className="tour-classic-caption">
                                    Klahvide - või kitarrisaatel kava, mis sobib rohkem rahulikku keskkonda, kus inimesed istuvad ja naudivad.
                                    Samuti hea eksprompt võimalus naistepäeva vms tähistamiseks kontoris ilma võimenduseta.
                                    Sobib ka pulmatseremooniale ning vahepalaks üritustele, konverentsidele jms.</p>
                                <div className="tour-classic-footer">
                                    <div className="tour-classic-footer-left">
                                        <p className="tour-classic-rating">Kestus:</p>
                                        <p className="tour-classic-reviews">kuni 2
                                            tundi. Kokkuleppel rohkem.</p>
                                    </div>
                                    <div className="tour-classic-footer-left">
                                        <p className="tour-classic-rating">Sobivus:</p>
                                        <p className="tour-classic-reviews">EST, ENG, RUS</p>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className="col-sm-6 col-lg-4 wow fadeIn" data-wow-delay=".05s">
                        <article className="tour-classic">
                            <div className="tour-classic-media"><a className="tour-classic-figure"
                                                                   href="#"><img
                                className="tour-classic-image" src={Img1} alt="" width="365"
                                height="248"/></a>
                                <div className="tour-classic-pricing">
                                    <p className="tour-classic-price tour-classic-price-new">Peobänd</p>
                                </div>
                            </div>
                            <div className="tour-classic-body">
                                <h4 className="tour-classic-title"><a href="#">Tantsu-, balli- või
                                    pulmakava</a></h4>
                                <p className="tour-classic-caption">Neljaliikmeline bänd, mis pakatab energiast ja ei jäta peol kedagi külmaks. Parim valik
                                    suvepäevadele, jõulupidudele ja pulmadesse.
                                    Esinetud 100+ üritusel ja pulmas ning siiani ühtegi seti, kus inimesed poleks tantsinud.
                                    Artjom Savitski garantii!</p>
                                <div className="tour-classic-footer">
                                    <div className="tour-classic-footer-left">
                                        <p className="tour-classic-rating">Kestus:
                                        </p>
                                        <p className="tour-classic-reviews">kuni 2h ehk 3 x 35-40 minutit.</p>
                                        <p className="tour-classic-rating">Sobivus:</p>
                                        <p className="tour-classic-reviews">EST, ENG, RUS</p>

                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>;
    }
}