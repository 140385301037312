import * as React from "react";


export default class Feedback extends React.Component {
    render() {

        return <section className="section section-lg bg-gray-300 text-center" id="feedback">
            <div className="container">
                <h2 className="text-uppercase font-weight-bold wow fadeIn with-divider-black">Tagasiside</h2>
                <div className="row row-40 row-md-50 row-xxl-80">
                    <div className="col-sm-12 col-lg-4 wow fadeIn">
                        <article className="post-classic"><a className="post-classic-figure"></a>
                            <p className="text-italic">" Istun siin ja mõtlen meie imelisele päevale - kui kiirelt see küll läks (eriti meie jaoks)
                                Pannes killukesi kokku, saan aru kui suur selles kõiges oli Sinul ja bändil. Tagasiside bändi
                                osas on olnud super - paljud nägid Sind uuest küljest, paljud ütlesid, et ei teadnudki, et
                                Sa nii hea oled.
                                Õnneks mina teadsin ja sai seetõttu õige valik tehtud.
                                Siinkohal SUURED TÄNUD Sulle meie poolt!
                                Loodan, et me teed veel kohtuvad ja Sinu esinemised lähevad üha paremaks ja teekond
                                on tõusuteel! "</p>
                            <div className="post-classic-divider"/>
                            <p className="price-eur pt-3">- Jana</p>
                        </article>
                    </div>
                    <div className="col-sm-12 col-lg-4 wow fadeIn" data-wow-delay=".05s">
                        <article className="post-classic"><a className="post-classic-figure"></a>
                            <p className="text-italic">" Tahame tänade Teid selle imelise õhtu ja muusika eest. Meile väga väga meeldis. Samuti
                                meie lapsele, kes päeviti Teie muusikat nõuab. Muu muusika talle ei meeldi, kui Teie
                                muusika ja Justament. :) Ühesõnaga oleme kolm suurt fänni. Täname Teid kõiki tõesti
                                väga ja loodame, et ka pidu meeldis Teile! "</p>
                            <div className="post-classic-divider"/>
                            <p className="price-eur pt-3">- Maria</p>
                        </article>
                    </div>
                    <div className="col-sm-12 col-lg-4 wow fadeIn" data-wow-delay=".05s">
                        <article className="post-classic"><a className="post-classic-figure"></a>
                            <p className="text-italic">" Saime lõpuks aega, et maha istuda ja kõigile, kes meie päeva nii ilusaks tegid ühe suure
                                AITÄH öelda!
                                Olite lihtsalt suurepärased - rahvas läks kohe käima ning tantsupõrand polnud kordagi
                                tühi! Jätsite kõigisse sügava jälje!
                                Sõbralikumat, lahkemat ja lahedamat bändi ei ole olemas!
                                Oleme väga tänulikud, et tegite meie päeva veel erilisemaks ja ilusamaks! "</p>
                            <div className="post-classic-divider"/>
                            <p className="price-eur pt-3">- Helen ja Tanel</p>
                        </article>
                    </div>
                    <div className="col-sm-12 col-lg-4 wow fadeIn" data-wow-delay=".05s">
                        <article className="post-classic"><a className="post-classic-figure"></a>
                            <p className="text-italic">" Südamlik tänuavaldus väga meeldiva esinemise eest. Nii nagu meile meeldis, meeldis ka
                                teistele, oleme ainult kiidusõnus kuulnud.
                                Suured kummardused meie poolt Teile, edu edaspidiseks.
                                NB! kontaktid on salvestatud. Kohtume järgmisel üritusel "</p>
                            <div className="post-classic-divider"/>
                            <p className="price-eur pt-3">- Janne ja Hegert</p>
                        </article>
                    </div>
                    <div className="col-sm-12 col-lg-4 wow fadeIn" data-wow-delay=".05s">
                        <article className="post-classic"><a className="post-classic-figure"></a>
                            <p className="text-italic">" Suur-suur aitäh Sulle nii ägeda etteaste eest!
                                Kuulsin ainult ylivõrdes tagasisidet! Öeldi, et nii vahetu suhtleja, hea hääl, vinged laulud,
                                ainuõige valik!!! "</p>
                            <div className="post-classic-divider"/>
                            <p className="price-eur pt-3">- Evelin</p>
                        </article>
                    </div>
                    <div className="col-sm-12 col-lg-4 wow fadeIn" data-wow-delay=".05s">
                        <article className="post-classic"><a className="post-classic-figure"></a>
                            <p className="text-italic">" Tahtsime veel väga tänada ülivahva emotsiooni eest! Sa tegid oma väiksed fännid nii
                                õnnelikuks eile. Eleanor tuli minu juurde ja ütles, et ma olen maailma õnnelikum. Küsisime
                                miks...., et Artjom meid parimateks tantsijateks kuulutas...aga päriselt olite
                                suurepärased!!!!! Tänud veelkord kogu meeskonnale"</p>
                            <div className="post-classic-divider"/>
                            <p className="price-eur pt-3">- Pert</p>
                        </article>
                    </div>
                </div>
            </div>
        </section>;
    }
}