import * as React from "react";
import Img1 from "../assets/images/img1.jpg";

export default class Events extends React.Component {
    render() {

        return <section className="section-sm bg-color-gray-800 bg-overlay-2 context-dark">
            <div className="container">
                <div className="row row-40 flex-lg-row-reverse align-items-center justify-content-xl-between">
                    <div className="col-sm-10 col-lg-6">
                        <div className="block-5 block-centered">
                            <div className="group group-3 group-middle wow fadeIn" data-wow-delay=".025s">
                                <h2 className="text-uppercase block-5-title with-divider-white">Esinemised</h2>
                            </div>
                            <h5 className="font-base opacity-70 font-weight-regular mt-20 mt-xl-20"
                                >Jälgi kus Artjom esineb ja mine vaatama!</h5>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-6 wow fadeIn clearfix">
                        <article className="owl-group-2">
                            <div className="owl-nav" id="owl-nav-2">
                                <button className="owl-arrow owl-arrow-next" aria-label="Next">
                                    <svg width="20" height="16" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928932C12.9526 0.538408 12.3195 0.538408 11.9289 0.928932C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM0 9H19V7L0 7L0 9Z"/>
                                    </svg>
                                </button>
                                <button className="owl-arrow owl-arrow-prev" aria-label="Prev">
                                    <svg width="20" height="16" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.7,15.1c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4L2.4,8l5.7-5.7c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L0.3,7.3										c-0.4,0.4-0.4,1,0,1.4L6.7,15.1z M20,7H1v2h19V7z"/>
                                    </svg>
                                </button>
                            </div>
                            <div className="owl-carousel" data-items="1" data-sm-items="2" data-lg-items="1"
                                 data-xl-items="2" data-dots="false" data-nav="false" data-stage-padding="0"
                                 data-nav-custom="#owl-nav-2" data-loop="true" data-margin="20" data-md-margin="30"
                                 data-xxl-margin="40" data-mouse-drag="false" data-autoplay="true">
                                <article className="tour-2"><a className="tour-2-media" href="#"><img
                                    className="tour-2-image" src={Img1} alt="" width="498" height="305"/>
                                    <div className="tour-2-badge">Esinemised</div>
                                </a>
                                    <div className="tour-2-caption context-light">
                                        <p className="tour-2-title"><a href="#">Telli Artjom
                                            esinema!</a></p>
                                    </div>
                                </article>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>;
    }
}