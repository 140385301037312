import * as React from "react";
import NavbarBrand from '../assets/images/valge.png';


export default class Footer extends React.Component {
    render() {

        return <footer className="section footer-classic">
            <div className="footer-classic-main context-dark bg-tertiary">
                <div className="container">
                    <div className="row">
                        <div className="footer-classic-layout col-12">
                            <div className="row">
                            <div className="text-center col-md-6 col-lg-4 col-12 order-0 order-lg-0 pb-5">
                                <div className="footer-classic-item-block footer-classic-item-block-1">
                                    <h4 className="footer-classic-title text-center">Viited</h4>
                                    <ul className="col-12 justify-content-center">
                                        <li className="pt-2 pb-2"><a href="#bio">Bio</a></li>
                                        <li className="pt-2 pb-2"><a href="#services">Telli esinema</a></li>
                                        <li className="pt-2 pb-2"><a href="#feedback">Tagasiside</a></li>
                                        <li className="pt-2 pb-2"><a href="#contact">Kontakt</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12 text-center order-2 order-lg-1 pb-5">
                                <img className="footer-brand" src={NavbarBrand}/>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12 text-center order-1 order-lg-2 pb-5">
                                <h4 className="footer-classic-title">Võta ühendust</h4>
                                <div className="footer-classic-item-block">
                                    <ul className="list list-md">
                                        <li><a href="mailto:info@artjomsavitski.ee"><span className="fa fa-envelope pr-2"/>info@artjomsavitski.ee</a></li>
                                        <li><a href="tel:+37258121511"><span className="fa fa-phone pr-2"/>+372 5812 1511</a></li>
                                    </ul>
                                </div>
                                <div className="group group-sm">
                                    <a className="link-1 icon mdi mdi-facebook" href="https://www.facebook.com/artjomsavitski"/>
                                    <a className="link-1 icon mdi mdi-instagram" href="https://www.instagram.com/artjomsavitski/"/>
                                    <a className="link-1 icon mdi mdi-youtube-play" href="https://www.youtube.com/user/artjomsavitski"/>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-classic-aside">
                <div className="container">
                    <p className="rights"><span>&copy;&nbsp; </span><span
                        className="copyright-year"/><span>&nbsp;</span><span>Artjom Savitski</span><span>. Kõik õigused reserveeritud.&nbsp;</span>
                    </p>
                </div>
            </div>
        </footer>;
    }
}