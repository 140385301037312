import * as React from "react";
import Img1 from "../assets/images/bio.jpg";

export default class About extends React.Component {
    render() {

        return <section className="section section-lg bg-default text-center" id="bio">
                <div className="container">
                    <h2 className="text-uppercase font-weight-bold wow fadeIn with-divider-black">Bio</h2>
                    <div className="row row-40 row-md-50 row-xxl-80">
                        <div className="col-sm-12 col-lg-6 wow fadeIn">
                            <article className=""><img src={Img1} alt=""/>
                            <div className="post-classic-divider"/>
                        </article>
                    </div>
                    <div className="col-sm-12 col-lg-6 wow fadeIn text-justify" data-wow-delay=".05s">
                        <p>Artjom Savitski on laulja ja meelelahutaja, kelle tuntus sai tuule tiibadesse teise kohaga
                            “Eesti otsib superstaari” neljanda hooaja saates 2011. aastal. Tema muusikutee algas 1999. aastal Tallinna Poistekooris Lydia
                            Rahula juhatusel. Seal lauldes tekkiski Artjomi suur armastus muusika vastu, mis ajendas teda ka iseseisvalt
                            erinevates bändides ja kollektiivides tegutsema. 2013. aastal esindas laulja Eestit rahvusvahelisel “Slavjanski Bazaar” noorte esitajate konkursil. <br/><br/>
                            Praeguseks on artistil väljas arvukalt soolosingleid, millest kuulsamad on:
                            “Tela” koos Andrei Zevakiniga, “Valin ise oma tee”, “Sinu juurde tagasi”, “Ainus soov”,
                            “Pozadi krutoi povorot” koos Anne Veskiga. <br/>
                            2015 novembris ilmunus ka Artjomi debüütalbum “Valin ise oma tee”, mis koosneb kümnest uuemast loost ning sellele lisaks ka muusiku kuuest varasemast singlist.
                            <br/><br/>Aastatega on meelelahutajana kogunenud suur kogemuste pagas ning Artjomi saab näha
                            nii õhtujuhi kui ka bändi laulja ja frontmani rollis klubides, festivalidel, pulmades ning
                            ettevõtete suvepäevadel ja jõulupidudel. Publikuks sobib nii eesti - kui ka venekeelne
                            kontingent. Samuti ideaalne variant, kui ürituse publikus on nii eesti kui vene ja miks ka
                            mitte inglise keelt kõnelevad inimesed. <br/><br/>
                            Artjom tegutseb aktiivselt ka sotsiaalmeedia influencerina eelkõige TikTokis, kus
                            on kogunenud üle 20 000 jälgija ning samuti ka Instagramis.
                            Tuntumad koostöö brändid: Samsung, Fanta, McDonald’s, Belief Energy Water, Viaplay
                            jne </p>
                    </div>
                </div>
            </div>
            </section>;
    }
}