import * as React from "react";
import NavbarBrand from '../assets/images/valge.png';
import BgImg from '../assets/images/bg.jpg';



export default class Navmenu extends React.Component {
    render() {

        return <header className="section page-header">
            <div className="rd-navbar-wrap rd-navbar-absolute">
                <nav className="rd-navbar rd-navbar-classic" data-layout="rd-navbar-fixed"
                     data-sm-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed"
                     data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static"
                     data-lg-device-layout="rd-navbar-fixed" data-xl-layout="rd-navbar-static"
                     data-xl-device-layout="rd-navbar-static" data-lg-stick-up-offset="1px"
                     data-xl-stick-up-offset="1px" data-xxl-stick-up-offset="1px" data-lg-stick-up="true"
                     data-xl-stick-up="true" data-xxl-stick-up="true">
                    <div className="rd-navbar-main-outer">
                        <div className="rd-navbar-main">
                            <div className="rd-navbar-panel">
                                <button className="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap">
                                    <span/></button>
                                <div className="rd-navbar-brand"><a className="brand" href="/"><img
                                    className="brand-logo-dark" src={NavbarBrand} alt="" width="140" height="53"
                                    srcSet={NavbarBrand}/></a>
                                </div>
                            </div>
                            <div className="rd-navbar-nav-wrap">

                                <ul className="rd-navbar-nav">
                                    <li className="rd-nav-item active"><a className="rd-nav-link"
                                                                          href="/">Avaleht</a>
                                    </li>
                                    <li className="rd-nav-item"><a className="rd-nav-link" href="#services">Telli
                                        esinema</a>
                                    </li>
                                    <li className="rd-nav-item"><a className="rd-nav-link" href="#bio">Bio</a>
                                    </li>
{/*                                    <li className="rd-nav-item"><a className="rd-nav-link" href="#merch">Merch</a>
                                    </li>*/}
                                    <li className="rd-nav-item"><a className="rd-nav-link" href="#feedback">Tagasiside</a>
                                    </li>
                                    <li className="rd-nav-item"><a className="rd-nav-link"
                                                                   href="#contact">Kontakt</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="rd-navbar-collapse-outer context-light">
                                <button className="rd-navbar-collapse-toggle"
                                        data-multitoggle="#rd-navbar-collapse, #toggle-inner"><span
                                    className="rd-navbar-collapse-toggle-element" id="toggle-inner"><span/></span><span
                                    className="rd-navbar-collapse-toggle-text">Info</span></button>
                                <div className="rd-navbar-collapse" id="rd-navbar-collapse">
                                    <button className="rd-navbar-collapse-close" data-multitoggle="#rd-navbar-collapse">
                                        <span className="rd-navbar-collapse-toggle-element active"><span/></span>
                                    </button>
                                    <h4 className="font-weight-bold text-uppercase">Esinemispaketid</h4>
                                    <h5 className="font-base font-weight-regular ls-1">Tutvu võimalustega lähemalt ning kutsu esinema!</h5>
                                    <h5 className="font-base font-weight-regular ls-1"><a
                                        href="tel:+37258121511">+372 5812 1511</a></h5>
                                    <div className="divider divider-small"/>
                                    <div className="owl-carousel owl-carousel-tour-minimal data-carousel-minimal"
                                         data-items="1" data-dots="true" data-nav="false" data-auto-width="true"
                                         data-loop="true" data-margin="20px" data-sm-margin="0px" data-autoplay="false"
                                         data-mouse-drag="false">
                                        <div className="owl-item-inner">
                                            <article className="tour-minimal context-dark">
                                                <div className="tour-minimal-inner"
                                                     style={{backgroundImage: `url(${BgImg})`}}>
                                                    <div className="tour-minimal-header">
                                                        <div className="tour-minimal-badge">Peobänd</div>
                                                    </div>
                                                    <div className="tour-minimal-main">
                                                        <p className="tour-minimal-comment">Kestus: kuni 2h.</p>
                                                    </div>
                                                    <div className="tour-minimal-caption">
                                                        <p>Tantsu-, balli- või
                                                            pulmakava</p>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="owl-item-inner">
                                            <article className="tour-minimal context-dark">
                                                <div className="tour-minimal-inner"
                                                     style={{backgroundImage: `url(${BgImg})`}}>
                                                    <div className="tour-minimal-header">
                                                        <div className="tour-minimal-badge">Fonogrammiga</div>
                                                    </div>
                                                    <div className="tour-minimal-main">
                                                        <p className="tour-minimal-comment">Kestus: kuni 40min</p>
                                                    </div>
                                                    <div className="tour-minimal-caption">
                                                        <p>Kui on soov pidutseda, tantsida või näppu visata</p>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="owl-item-inner">
                                            <article className="tour-minimal context-dark">
                                                <div className="tour-minimal-inner"
                                                     style={{backgroundImage: `url(${BgImg})`}}>
                                                    <div className="tour-minimal-header">
                                                        <div className="tour-minimal-badge">Akustiline duo</div>
                                                    </div>
                                                    <div className="tour-minimal-main">
                                                        <p className="tour-minimal-comment">Kestus: kuni 2h, kokkuleppel ka kauem</p>
                                                    </div>
                                                    <div className="tour-minimal-caption">
                                                        <p>Klahvide - või kitarrisaatel kava</p>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rd-navbar-placeholder"/>
                </nav>
            </div>
        </header>;
    }
}